import { IconLock } from "@tabler/icons-react";


const LockedTag = () => {
    return (
        <div className="flex items-center gap-1 bg-zenbeliGreen p-1 rounded-lg absolute top-1 right-1 text-black">
            <IconLock className="w-4 h-4 text-black" />
            {/* <span className="text-[8px]">Smart Dashboard</span> */}
        </div>
    )
}

export default LockedTag;