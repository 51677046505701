// SideNavbar.tsx
import React, { useState, useRef, useEffect } from 'react';
import { IconMenu2, IconX, IconHome, IconUser, IconSettings, IconDashboard, IconReport, IconDeviceDesktopCheck  } from '@tabler/icons-react';
import { useUser } from '../hooks/useUser';
import { useLogout } from '../hooks/authentication_helper';
import { Link, useNavigate } from 'react-router-dom';
import { cn } from '../lib/utils';
import { useZenbeliBackend } from '../hooks/useZenbeliBackend';
import LoginModal from './LoginModal';
import Modal from 'react-modal';


interface SideNavbarProps {
  logo?: React.ReactNode;
  children: React.ReactNode;
  hamburgerClassName?: string;
}

const SideNavbar = ({ logo, children, hamburgerClassName }: SideNavbarProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const sidebarRef = useRef<HTMLDivElement>(null);

  // Function to handle opening the sidebar
  const openSidebar = () => setIsOpen(true);

  // Function to handle closing the sidebar
  const closeSidebar = () => setIsOpen(false);

  // Close sidebar when clicking outside
  useEffect(() => {
    const handleOutsideClick = (event: MouseEvent) => {
      if (
        isOpen &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        closeSidebar();
      }
    };

    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [isOpen]);



  return (
    <>
      {/* Hamburger Icon */}
      <button
        className={cn("p-4 text-white w-fit focus:outline-none", hamburgerClassName)}
        onClick={openSidebar}
        aria-label="Open sidebar"
      >
        <IconMenu2 size={24} />
      </button>

      {/* Overlay */}
      {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 z-40" />
      )}

      {/* Sidebar */}
      <div
        ref={sidebarRef}
        className={`fixed top-0 left-0 h-full w-64 bg-white shadow-lg transform ${
          isOpen ? 'translate-x-0' : '-translate-x-full'
        } transition-transform duration-300 ease-in-out z-50`}
      >
        {/* Close Icon and Logo */}
        <div className="flex items-center justify-between p-4 border-b">
          <div className="flex items-center space-x-2">
            {logo ? (
              logo
            ) : (
              <span className="text-xl font-semibold">Logo</span>
            )}
          </div>
          <button
            className="p-2 text-gray-700 focus:outline-none"
            onClick={closeSidebar}
            aria-label="Close sidebar"
          >
            <IconX size={24} />
          </button>
        </div>

        {/* Navigation Items */}
        <nav className="p-4">
          {children}
        </nav>
      </div>
    </>
  );
};


interface ResetSurveyModalProps {
  isOpen: boolean;
  setIsOpen: (v: boolean) => void;
}

const ResetSurveyModal = ({isOpen, setIsOpen}: ResetSurveyModalProps) => {
  const db = useZenbeliBackend()

  const resetSurvey = async () => {
      const response = await db.endpoints.api.resetSurveyCreate()
      console.log(response)
      if(response.status === 200){
          window.location.href = "/user-profile-builder"
      }
  }

  return(
    <Modal
    isOpen={isOpen}
    onRequestClose={() => (setIsOpen(false))}
    style={{
      overlay: {
        background: 'transparent',
        zIndex: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        border: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'fit-content',
        height: 'auto',
        inset: 'auto',
        background: 'transparent',
        // Mobile media query
        // @ts-ignore
        '@media (max-width: 768px)': {
          padding: '1rem',
        },
        zIndex: 1000
      }
    }}
  >
    <div className='bg-white rounded'>
      <div className="flex flex-col gap-6 p-8">
        <div>
          This will reset your reports and formerly answered user data.
          <div>Are you sure you would like to restart the survey?</div>
        </div>
        <div className="flex flex-row gap-5">
          <button className="bg-gradient-to-br relative group/btn from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]" onClick={() => setIsOpen(false)}>No</button>
          <button className="bg-gradient-to-br relative group/btn from-black dark:from-zinc-900 dark:to-zinc-900 to-neutral-600 block dark:bg-zinc-800 w-full text-white rounded-md h-10 font-medium shadow-[0px_1px_0px_0px_#ffffff40_inset,0px_-1px_0px_0px_#ffffff40_inset] dark:shadow-[0px_1px_0px_0px_var(--zinc-800)_inset,0px_-1px_0px_0px_var(--zinc-800)_inset]" onClick={resetSurvey}>Yes</button>
        </div>
      </div>
    </div>
  </Modal>
  )
}


const DefaultSidebar = ({hamburgerClassName}: {hamburgerClassName?: string}) => {
    const userApi = useUser();
    const loggedIn = userApi.state === 'LoggedIn';
    const survey_completed = loggedIn && userApi.user?.completed_user_survey;
    const logout = useLogout();
    const navigate = useNavigate();
    const [loginModalOpen, setLoginModalOpen] = useState(false);
    const [resetSurveyModalIsOpen, setResetSurveyModalIsOpen] = useState(false);

    
    return (
    <SideNavbar
        logo={<img src="ZenBeli-sub-app-black-green.png" alt="Zenbeli" className="h-8" />} hamburgerClassName={hamburgerClassName}
    >
        <ResetSurveyModal isOpen={resetSurveyModalIsOpen} setIsOpen={setResetSurveyModalIsOpen} />
        <LoginModal isOpen={loginModalOpen} setIsOpen={setLoginModalOpen} />
        <ul className="space-y-4 text-gray-700">
        <li className="flex items-center space-x-2">
            <IconHome size={20} />
            <Link to="/" className="text-gray-700 hover:text-gray-900">
                Home
            </Link>
        </li>
             {
                loggedIn ? (
                    [                       
                      survey_completed ? (<>
                          <li className="flex items-center space-x-2">
                                <IconDashboard size={20} />
                                <Link to="/zenbeli_digestive_assistant" className="text-gray-700 hover:text-gray-900">
                                    Dashboard
                                </Link>
                            </li>
                          <li className="flex items-center space-x-2">
                            <IconReport size={20} />
                            <div onClick={() => setResetSurveyModalIsOpen(true)} className="text-gray-700 hover:text-gray-900">
                                Restart Survey
                            </div>
                          </li>
                          <li>
                            <a className="flex items-center space-x-2" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSccLwIcN5j1kGjY6QAq2OZ1OM9qsCelbPrQoKYLdoZrOu4Xgg/viewform">
                              <IconDeviceDesktopCheck size={20} />
                              <div>Help Us Improve</div>
                            </a>
                          </li>
                          </>) : 
                        (
                            <li className="flex items-center space-x-2">
                                <IconReport size={20} />
                                <Link to="/user-profile-builder" className="text-gray-700 hover:text-gray-900">
                                    Start Assesment
                                </Link>
                            </li>
                        ),
                        <li className="flex items-center space-x-2">
                            <IconSettings size={20} />
                            <Link to="/account" className="text-gray-700 hover:text-gray-900">
                                Manage Account
                            </Link>
                        </li>,
                        <li className="flex items-center space-x-2">
                            <IconUser size={20} />
                            <div onClick={() => {
                                logout()
                                navigate("/")
                              }
                             } className="text-gray-700 hover:text-gray-900">
                                Logout
                            </div>
                        </li>
                    ]   
                ) :
                [
                    <li className="flex items-center space-x-2 hover:text-gray-900 cursor-pointer">
                        <IconUser size={20} />
                        <div onClick={() => setLoginModalOpen(true)} className="text-gray-700 hover:text-gray-900">
                            Login
                        </div>
                    </li>,
                ]
            }
        </ul>
    </SideNavbar>
    );
};

export default DefaultSidebar;
