const ZenbeliWhiteIcon = ({className}: {className: string}) => {
    return(
        <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="259.7" height="174.98" viewBox="0 0 259.7 174.98" className={className}>
            <defs>
                <style>
                    {`.cls-1 {
                        fill: url(#New_Gradient_Swatch_8);
                    }

                    .cls-2 {
                        fill: none;
                        stroke: #fff;
                        stroke-miterlimit: 10;
                        stroke-width: 2.13px;
                    }`}
                </style>
                <radialGradient id="New_Gradient_Swatch_8" data-name="New Gradient Swatch 8" cx="82.06" cy="140.78" fx="82.06" fy="140.78" r="25.55" gradientTransform="translate(55.52 -114.59) rotate(-1.93)" gradientUnits="userSpaceOnUse">
                <stop offset="0" stop-color="#94eb00"/>
                <stop offset="1" stop-color="#54af2c"/>
                </radialGradient>
            </defs>
            <path className="cls-1" d="M126.5,52.6l29.89-33.96-24.05,34.14c1.76.02,3.53,0,5.31-.05,7.59-.26,14.96-1.18,21.97-2.71,11.52-15.49,11.47-50.02,11.47-50.02,0,0-34.48,8.21-46.45,23.39-7.22,9.16-10.77,19.47-10.39,27.87,4,.65,8.09,1.11,12.25,1.34Z"/>
            <path className="cls-2" d="M105.13,89.77c8.71.85,17.63,1.3,26.66,1.3,7.23,0,14.41-.29,21.48-.85,22.2-7.31,38.3-24.7,40.05-45.33-17.12,9.91-39.18,15.32-62.76,15.32s-47.05-5.85-64.22-16.18c1.34,20.59,16.98,38.07,38.79,45.73Z"/>
            <path className="cls-2" d="M90.47,128.67c12.48,1.08,25.31,1.64,38.3,1.64,11.58,0,23.07-.46,34.3-1.33,34.95-8.84,60.13-30.24,61.95-55.5-25.98,12.31-60.05,19.05-96.57,19.05s-72.8-7.29-98.75-20.1c1.17,25.42,25.99,47.09,60.78,56.24Z"/>
            <path className="cls-2" d="M1.13,108.82c1.39,36.01,58.6,65.09,128.73,65.09s126.14-28.47,128.67-63.97c-34,13.29-78.98,20.59-127.28,20.59S35.04,122.65,1.13,108.82Z"/>
        </svg>
    )
}

export default ZenbeliWhiteIcon;