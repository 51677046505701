import styled from '@emotion/styled';
import { useUser } from '../hooks/useUser';
import { ForwardRefExoticComponent, RefAttributes, useEffect, useState } from 'react';
import { SignupForm } from '../components/Signup';
import { LoginForm } from '../components/LoginForm';
import { Icon, IconArrowLeft, IconProps } from '@tabler/icons-react';
import IconButtonSelection from '../components/IconButtonSelection';
import {Button} from '../components/ui/moving-border';
import DefaultSidebar from '../components/Navbar';
import TypeAheadMultiSelect from '../components/TypeAheadMultiSelect';
import { useZenbeliBackend } from '../hooks/useZenbeliBackend';
import { useNavigate } from 'react-router-dom';
import ZenLoading from '../components/ZenLoading';

export interface Option {
    icon?: ForwardRefExoticComponent<IconProps & RefAttributes<Icon>>;
    name: string;
    id: string
}


interface Question {
    id: string;
    name: string;
    options: Option[];
    type: string;
    completed: boolean;
    progress: number;
    leaveFlowAction?: {
        redirect: string;
        button_label: string;
    }
}

export interface QuestionComponentProps {
    options: Option[];
    setAnswer: (answer: any) => void;
    answer: any;
}
// Define the type of functions in the QuestionComponents object
type QuestionComponentFunction = ({options}: QuestionComponentProps) => JSX.Element;

const QuestionComponents: { [key: string]: QuestionComponentFunction } = {
    singleChoice: ({options, answer, setAnswer}: QuestionComponentProps) => {
        return (
            <div>
                <select onChange = {(e) => setAnswer(parseInt(e.target.value))} value = {answer || ""}>
                    {options.map((option) => (
                        <option key={option.name}>{option.name}</option>
                    ))}
                </select>
            </div>
        );
    },
    textInput: ({options, answer, setAnswer}: QuestionComponentProps) => {
        return(
            <textarea placeholder="I eat a low carb diet" onChange={e => setAnswer(e.target.value)} value = {answer || ""} className="flex h-10 w-full border-none bg-gray-50 dark:bg-zinc-800 text-black dark:text-white shadow-input rounded-md px-3 py-2 text-sm  file:border-0 file:bg-transparent 
            file:text-sm file:font-medium placeholder:text-neutral-400 dark:placeholder-text-neutral-600 
            focus-visible:outline-none focus-visible:ring-[2px]  focus-visible:ring-neutral-400 dark:focus-visible:ring-neutral-600
             disabled:cursor-not-allowed disabled:opacity-50
             dark:shadow-[0px_0px_1px_1px_var(--neutral-700)]
             group-hover/input:shadow-none transition duration-400"/>
        )

    },
    singleChoiceComponent: ({options, setAnswer, answer}:QuestionComponentProps) => {
        return (
            <IconButtonSelection setAnswer ={setAnswer} answer={answer} options={options} />
        );
    },
    multipleChoice: ({options, setAnswer, answer}: QuestionComponentProps) => {
        return(
            <TypeAheadMultiSelect options={options} setAnswer={setAnswer} answer={answer || []} />
        ) 
    },
    multipleChoiceWithCreateOption: ({options, setAnswer, answer}: QuestionComponentProps) => {
        return(
            <TypeAheadMultiSelect canCreate={true} options={options} setAnswer={setAnswer} answer={answer || []} />
        )
    }
};

interface QuestionLoaderProps {
    question: Question;
    setAnswer: (answer: any) => void;
    answer: any;
}

const QuestionLoader = ({question, setAnswer, answer}: QuestionLoaderProps) => {
    // Check that the question type is in the QuestionComponents object
    const questionType = question.type;
    const QuestionComponent = QuestionComponents[questionType];
    // Do correct now
    

    if(QuestionComponent) {
        return <QuestionComponent setAnswer = {setAnswer} answer = {answer} options = {question.options} />
    }else{
        return (
            <div>
                <h1>Question type not supported</h1>
            </div>
        )
    }
}

const Container = styled.div`
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    padding: 2rem;
    `;

const SubmitBar = ({answer, nextQuestion}: {answer: any, nextQuestion: () => void}) => {
    if(!answer || (Array.isArray(answer) && answer.length === 0)) return null;
    return(
        <div className="flex justify-center items-center">
            <Button onClick={nextQuestion} borderRadius='.75rem' disabled={!answer}>Next</Button>
        </div>
    )
}

const QuestionContainer = styled.div`
    animation: fadeIn 1s;
    @keyframes fadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
`;

const ProgressBar = ({progress}: {progress: number}) => {
    return(
        <div className="flex justify-center items-center gap-4">
            <div className="w-full h-2 bg-gray-300 rounded-lg">
                <div className="h-full bg-zenbeliGreen rounded-lg" style={{width: `${progress * 100}%`}}></div>
            </div>
        </div>
    )
}


const QuestionFlow = () => {
    // const userApi = useUser();
    const db = useZenbeliBackend()
    const [question, setQuestion] = useState<Question|null>();
    const [answer, setAnswer] = useState<number | string | null>(null);
    const navigate = useNavigate()
    const [loading, setLoading] = useState<boolean>(false)

    const nextQuestion = async () => {
        if(!question) return;
        const questionId = question.id;
        setQuestion(null)
        await db.endpoints.api.saveAnswerCreate({question_id: questionId, answer: answer as any})
        const response = await db.endpoints.api.getNextQuestionRetrieve()
        setAnswer(null)
        setQuestion(response.data as any)
        console.log(response.data)
    }


    useEffect(() => {
        if(question) return;
        if(loading) return;
        console.log("Running next question")
        setLoading(true)
        db.endpoints.api.getNextQuestionRetrieve().then((response) => {
            setQuestion(response.data as any);
            setLoading(false)
        })
    }, [])

    const previousQuestion = async () => {
        if(!question) return;
        const questionId = question.id;
        setQuestion(null)
        const response = await db.endpoints.api.getPreviousQuestionCreate({current_question_id: questionId})
        // ({current_question_id: questionId})
        setQuestion(response.data as any)
    }


    if(!question) return <ZenLoading />

    return(
        <div>
            {question.progress && question.id != '1' ? <div className="m-2" onClick={previousQuestion}>
                <IconArrowLeft size={32} />
            </div> : <></>}
            <ProgressBar progress= {question.progress || 0} />
            <QuestionContainer key={question.id} className="flex font-museo-sans text-white flex-col gap-2 p-4 md:px-24">
                <div className="py-4 text-lg md:text-xl">{question.name}</div>
                {
                    !!!question.completed ? <>
                        <QuestionLoader question={question} setAnswer = {setAnswer} answer={answer}/>
                        <SubmitBar nextQuestion={nextQuestion} answer={answer}/>
                    </> :
                        <div className="flex justify-center items-center">
                            <Button onClick={()=> {window.location.href = question?.leaveFlowAction?.redirect || ""}} borderRadius='.75rem'>{question?.leaveFlowAction?.button_label}</Button>
                        </div>
                }
            </QuestionContainer>
        </div>
    )
}

const Authenticate = () => {
    // Get the state and redirect url from the query parameters using react router
    const [loggingIn, setLoggingIn] = useState<boolean>(false);

    return(
        <div className="flex flex-col justify-center text-center w-full gap-4 pt-12">            
            {loggingIn ? <div className="text-white">Don't have an account? <button onClick={() => setLoggingIn(false)}  className="shadow-[0_0_0_3px_#fff_inset] px-6 py-2 bg-transparent border border-white text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">Sign Up</button></div> :
            <div className="text-white"> Already have an account? <button onClick={() => setLoggingIn(true)}  className="shadow-[0_0_0_3px_#fff_inset] px-6 py-2 bg-transparent border border-white text-white rounded-lg font-bold transform hover:-translate-y-1 transition duration-400">Log In</button></div>}

            {loggingIn ? 
                <LoginForm  /> :
                <SignupForm  /> 
            }
        </div>
    )

}


const UserProfileBuilder = () => {
    const userApi = useUser();
    const loggedIn = userApi.state === 'LoggedIn';
    

    return(
        <Container className="bg-zenbeliBlue">
            <div className="flex flex-row gap-4 pb-8 justify-start items-center">
                <DefaultSidebar />
                <img src="zenbeli-sub-app_white-green.png" alt="Zenbeli Logo" className="self-start w-2/3 mt-1 md:m-0 md:w-1/3"/>
            </div>
            {
                !loggedIn ?
                <Authenticate /> :
                <QuestionFlow />
            }
        </Container>
    )
}

export default UserProfileBuilder;