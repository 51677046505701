import Modal from 'react-modal';
import { cn } from '../lib/utils';
import { useZenbeliBackend } from "../hooks/useZenbeliBackend";
import { useState, useEffect } from 'react';
import ZenLoading from './ZenLoading';
import { IconX } from '@tabler/icons-react';


export const SubscriptionModal = ({ isOpen, setIsOpen }: { isOpen: boolean, setIsOpen: (v: boolean) => void }) => {
    const db = useZenbeliBackend()


    const [checkoutURL, setCheckoutURL] = useState<string | null>(null)

    const getCheckoutURL = async () => {
        const response = await db.endpoints.api.stripeCheckoutCreate({
            slug: 'smart-dashboard'
        })
        console.log("Response",response)
        if (response.data) {
            setCheckoutURL(response.data.stripe_checkout_url)
        }
    }

    useEffect(() => {
        getCheckoutURL()
    }, [])

    return   <Modal
    isOpen={isOpen}
    onRequestClose={() => (setIsOpen(false))}
    style={{
      overlay: {
        background: 'transparent',
        zIndex: 100,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      content: {
        border: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: 'fit-content',
        height: 'auto',
        inset: 'auto',
        background: 'transparent',
        // Mobile media query
        // @ts-ignore
        '@media (max-width: 768px)': {
          padding: '1rem',
        },
        zIndex: 1000
      }
    }}
  >
    <div className="bg-white p-4 rounded-lg drop-shadow-lg">
        {
            !!!checkoutURL ? 
            <ZenLoading /> :
            <div className="flex flex-col gap-4">
                <IconX className="w-4 h-4 absolute top-2 right-9" onClick={() => setIsOpen(false)} />
                <div className="flex flex-col gap-4">
                    <h3 className="text-xl font-bold text-center">Personalize your wellness experience</h3>
                    <h4 className="text-lg font-semibold">Upgrade now and receive:</h4>
                    <ul className="list-disc pl-6 space-y-2">
                        <li><strong>Downloadable Non-Medical Reports</strong> to share with healthcare and wellness providers</li>
                        <li><strong>Curated list of trigger foods</strong></li>
                        <li><strong>Curated Healthy food lists and recipes</strong></li>
                        <li><strong>Deeper Assessments</strong></li>
                        <li><strong>Knowledge Center:</strong> more about your condition, customized content from our Licensed Director of Health, Wellness and Compliance</li>
                        <li><strong>Automatic enrollment</strong> to new assessment roll-outs</li>
                        <li><strong>Automatic enrollment</strong> to Zenbeli Communities <span className="text-sm font-medium">(Beta)</span></li>
                    </ul>
                </div>
                <a href={checkoutURL} className="bg-zenbeliGreen text-white px-4 py-2 rounded-lg drop-shadow-lg w-fit">
                    Subscribe
                </a>
            </div>
        }
    </div>
  </Modal>
}

const SubscribeButton = () => {
    const [isOpen, setIsOpen] = useState(false)
    return <>
        <button className="bg-zenbeliGreen text-white px-4 py-2 rounded-lg drop-shadow-lg w-fit" onClick={() => setIsOpen(true)}>Subscribe</button>
        <SubscriptionModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
}

export default SubscribeButton