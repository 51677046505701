import { useNavigate } from "react-router-dom";
import DefaultSidebar from "../components/Navbar";
import { useEffect, useState, useRef, useCallback } from "react";
import { useZenbeliBackend } from "../hooks/useZenbeliBackend";
import ZenLoading from "../components/ZenLoading";
import Markdown from 'react-markdown'
import styled from '@emotion/styled';
import { IconCandyOff, IconChefHat, IconDownload, IconFridge, IconMedicalCross, IconReport, IconUser, IconLoader } from "@tabler/icons-react";
import { SubscriptionModal } from "../components/SubscribeButton";
import LockedTag from "../components/ui/locked-tag";
//Get  REACT_APP_BACKEND_URL
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL || "http://localhost:8000";

const FormattingContainer = styled.div`
    p {
        margin: .5rem;
    }
    ul {
        padding-left: 1rem;
        margin: .5rem;
        type: disc;
    }
    ol {
        padding-left: 1rem;
        margin: .5rem;
        list-style-type: number;
    }
    h1, h2, h3, h4, h5, h6 {
        margin: 0;
        font-weight: bold;
    }
`


const ActionButton = styled.div<{ selected?: boolean, disabled?: boolean }>`
    @media (max-width: 768px) {
        font-size: .75rem;
        width: 6rem;
    }

    ${props => !!props.disabled ? "opacity: 0.5; pointer-events: none;" : ""}

    ${props => !!props.selected ? "border-bottom: 2px solid #94eb00;" : "border-bottom: 1px solid #e2e8f0;"}

    text-wrap: none;
    flex-direction: column;
    position: relative;
    
    text-align: center;
    padding: .25rem;
    display: flex;
    gap: .5rem;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
        background-color: #d9f1f3;
        border-bottom: 1px solid #e2e8f0;
        color: black;
        & >svg {
            color: black;
        }
    }
    & > svg {
        color: #94eb00;
    }
    color: white;
`


const UserProfile = ({ user }: { user: any }) => {
    return (
        <>
            <div>
                {user.diagnosed_conditions && <><span className="text-bold">Diagnosed Conditions:</span> {
                    user.diagnosed_conditions.map((condition: any) => {
                        return (
                            <div className="flex flex-row gap-2">
                                {condition.name}
                            </div>
                        )
                    })
                }</>}
            </div>

            <div className="flex flex-col gap-1">
                {user.symptoms && <><span className="text-bold">Symptoms:</span> {
                    user.symptoms.map((symptom: any) => {
                        return (
                            <div>
                                {symptom.name}
                            </div>
                        )
                    })
                }</>
                }
            </div>


            {user.severity_of_symptoms && <div><span className="text-bold">Severity of Symptoms:</span> {user.severity_of_symptoms}</div>}
            {user.impact_on_life_quality && <div><span className="text-bold">Impact On Quality Of Life:</span> {user.impact_on_life_quality}</div>}
            {user.comfort_level && <div><span className="text-bold">Comfort In Social Situations:</span> {user.comfort_level}</div>}
            {user.food_intolerances && <div className="flex gap-1 flex-col"><span className="text-bold">Food Intolerances:</span> {
                user.food_intolerances.map((food: any) => {
                    return (
                        <div>
                            {food.name}
                        </div>
                    )
                })
            }</div>
            }
            {user.current_diet_management && <div><span className="text-bold">Current Diet Management:</span> {user.current_diet_management}</div>}


        </>
    )
}

const ZenbeliDigestiveAssistant = () => {
    const [userData, setUserData] = useState<any>()
    const db = useZenbeliBackend()
    const navigate = useNavigate()
    const bottomScroll = useRef<HTMLDivElement | null>(null);
    const [tab, setTab] = useState<string>("user_profile")
    const [reportLoading, setReportLoading] = useState<boolean>(false)
    const [reports, setReports] = useState<any>({})
    const [loadingUserReports, setLoadingUserReports] = useState<boolean>(true)
    const [isOpen, setIsOpen] = useState(false)

    const subscribed =  userData?.user?.data?.subscription?.is_active
    console.log("Is subscribed", subscribed)


    useEffect(() => {
        db.endpoints.api.userProfileRetrieve().then((result: any) => setUserData({ state: "loggedIn", user: { status: "loaded", data: result.data.user } }))
            .catch(() => navigate("/"))
    }, [])

    useEffect(() => {
        console.log("Running Poll!")
        pollUserReports()
    }, [])


    // dynamically call tab method from teh tab variable
    const POSSIBLE_REPORTS = ["what_to_eat", "what_to_avoid", "generate_recipes", "more_about_condition", "doctors_visit_report"];

    const getUserReports = async () => {
        const result = await db.endpoints.api.userReportsRetrieve();
        return result.data;
    };

    const pollUserReports = async () => {
        console.log("Poll User Reports")

        const new_reports = await getUserReports() as any;
        console.log("Report", new_reports);
        // Check if all reports are available
        if (!POSSIBLE_REPORTS.every((report) => new_reports[report])) {
            console.log("Reports are not complete");
            setTimeout(pollUserReports, 5000);
        }else{
            setLoadingUserReports(false)
        }

        setReports(new_reports);
    }
    

    const downloadPDF = useCallback(async () => {
        setReportLoading(true)
        try {
            // Use the fetch API to get the PDF as a Blob
            const csrfToken = document.cookie.match(/csrftoken=([^;]+)/)?.[1]; // Get CSRF token from cookies
            const response = await fetch(BACKEND_URL + '/api/pdf_export/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-CSRFToken': csrfToken || '', // Include CSRF token
                },
                credentials: 'include', // Include credentials (cookies) in the request
                body: JSON.stringify({ report_type: tab })
            });

            // Check if the response is okay
            if (!response.ok) {
                throw new Error("Failed to fetch PDF");
            }

            // Get the response as a Blob
            const blob = await response.blob();

            // Create an object URL from the Blob
            const url = window.URL.createObjectURL(blob);

            // Create a link element, set the download attribute, and trigger download
            const a = document.createElement('a');
            a.href = url;
            a.download = "digestive_assistant.pdf";
            document.body.appendChild(a);
            a.click();

            // Cleanup: Remove the link and revoke the object URL
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error("Failed to download the PDF:", error);
        }
        setReportLoading(false)
    }, [tab]);


    if (!userData) return <div className="p-8"><ZenLoading /></div>


    const ReportsConfiguration = [
        {
            name: "what_to_eat",
            title: "What To Eat",
            icon: IconFridge
        },
        {
            name: "what_to_avoid",
            title: "What To Avoid",
            icon: IconCandyOff
        },
        {
            name: "generate_recipes",
            title: "Generate Recipes",
            icon: IconChefHat
        },
        {
            name: "more_about_condition",
            title: "More About Condition",
            icon: IconReport
        },
        {
            name: "doctors_visit_report",
            title: "Doctor's Visit Report",
            icon: IconMedicalCross
        }
    ]


    const ActionBar = () => {
        return (
            <div className="flex gap-2 p-[20px] sticky w-full bg-zenbeliDarkBlue rounded-[10px]">
                <ActionButton selected={tab === "user_profile"} onClick={() => setTab("user_profile")}>
                    <IconUser/>
                    <span>View Your Data</span>
                </ActionButton>
                {
                    ReportsConfiguration.map((report) => {
                        const accessible = reports[report.name]?.accessible === undefined ? true : !!reports[report.name]?.accessible
                        return (
                            <ActionButton disabled={!reports[report.name]} selected={tab === report.name} onClick={!accessible ? () => setIsOpen(true) : () => setTab(report.name)}>
                                <report.icon />
                                <span>{report.title}</span>
                                {
                                    !accessible && <LockedTag />
                                }
                            </ActionButton>
                        )
                    })
                }
            </div>
        )
    }

    const user = userData.user.data
    const POSSIBLE_REPORTS_COUNT = 5
    return (<div className="flex flex-col gap-2 p-8 text-left text-white h-full min-h-[100vh] bg-zenbeliBlue">
        <SubscriptionModal isOpen={isOpen} setIsOpen={setIsOpen} />
        <div className="flex justify-between flex-row">
            <DefaultSidebar />
            <div className="flex flex-row gap-2 justify-center items-center">
                <span>{user.user.first_name} {user.user.last_name}</span>
                <img src="LotusZenbeli.png" alt="Zenbeli Logo" className="w-[33px] h-[21.3px]" />
            </div>
        </div>
        <ActionBar />
        {loadingUserReports && <div className="font-bold flex flex-row gap-4"><div>User Reports Processed: {Object.keys(reports).length / POSSIBLE_REPORTS_COUNT * 100}%</div></div>}
        {
            tab !== "user_profile" && <div className="w-full flex flex-row gap-5">
                <button onClick={subscribed ? downloadPDF : () => setIsOpen(true)} className="relative px-4 py-2 flex bg-zenbeliGreen hover:bg-[#7bc700] rounded-md justify-center items-center gap-1">
                    Download Report <IconDownload className="h-[20px] w-[20px] inline" />
                    {
                        !subscribed && <LockedTag />
                    }
                </button>
                {reportLoading && <IconLoader className="w-4 h-4" />}
            </div>
        }

        {
            tab === "user_profile" ? 
                <UserProfile user={user} /> : 
                (
                    reports && reports[tab] ? 
                    <FormattingContainer><Markdown>{reports[tab].content}</Markdown></FormattingContainer> :
                    <ZenLoading />
                )
        }
        <div ref={bottomScroll}></div>
    </div>)
}

export default ZenbeliDigestiveAssistant;