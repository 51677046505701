import { Navigate } from "react-router-dom";
import DefaultSidebar from "../components/Navbar"
import { useUser } from "../hooks/useUser";
import { useZenbeliBackend } from "../hooks/useZenbeliBackend";
import { useState, useEffect } from "react";
import ZenLoading from "../components/ZenLoading";
import SubscribeButton from "../components/SubscribeButton";

const Account = () => {
    const userApi = useUser();
    const db = useZenbeliBackend();
    const [stripePortalUrl, setStripePortalUrl] = useState<string | null>(null)

    const getStripeManagementLink = async () => {
        const response = await db.endpoints.api.stripePortalCreate()
        console.log(response)
        setStripePortalUrl(response.data.stripe_portal_url)
    }


    useEffect(() => {
        getStripeManagementLink()
    }, [])
        

    if (userApi.state !== "LoggedIn") return <Navigate to="/login" />

    const user = userApi?.user;
    const subscribed = !!user?.subscription?.is_active






    return (
        <div className="flex flex-col gap-2 p-8 text-left bg-zenbeliBlue min-h-[100vh]">
            <DefaultSidebar />
            <div className="flex flex-col gap-2 w-full">
                <h1 className="text-2xl font-bold text-white">Account</h1>
                { subscribed ? <p className="text-white">You are subscribed to Zenbeli Smart Dashboard</p> : <>
                    <p className="text-white">You can subscribe to Zenbeli Smart Dashboard to access all the features.</p>
                    <SubscribeButton />
                </> }
                <div className="flex flex-col gap-2">
                    <p className="text-white">Manage Subscription And Payments</p>
                    { stripePortalUrl ? <a className="bg-zenbeliGreen text-white px-4 py-2 rounded-lg drop-shadow-lg w-fit" href={stripePortalUrl}>Manage Subscription And Payments</a>  : <ZenLoading /> }
                </div>
            </div>
        </div>
    )
}

export default Account;